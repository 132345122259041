<template>
  <div class="organizations-create page-layout">
    <PageHeader
        title="Création d'un cabine"
        :has-back="true"
        :has-search="false"
        :has-actions="false"
        @back="goBack"
    />

    <md-card class="meep-form">
      <!-- le formulaire de création -->
      <md-card-content>
        <div class="meep-form-column">
          <!-- Nom -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('name') }">
              <label>Nom du cabinet *</label>

              <md-input
                  v-model="form.name"
                  v-validate="'required'"
                  name="name"
                  type="text"
              />

              <span v-show="errors.has('name')" class="md-error">
                {{ errors.first("name") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Logo -->
            <md-field>
              <label>
                Logo au format .svg 220x128px
              </label>

              <md-file accept=".svg" @md-change="onFileAdded($event, 'logo')" />
            </md-field>
          </div>

          <object
              v-show="logoPreview !== null"
              id="logo-preview"
              :data="logoPreview"
              type="image/svg+xml"
              class="logo-preview"
          />

          <div class="meep-input">
            <!-- Logo Minified -->
            <md-field>
              <label>
                Logo Minified au format .svg 49x42px
              </label>

              <md-file
                  accept=".svg"
                  @md-change="onFileAdded($event, 'logoMinified')"
              />
            </md-field>
          </div>

          <object
              v-show="logoMinifiedPreview !== null"
              id="logo-minified-preview"
              :data="logoMinifiedPreview"
              type="image/svg+xml"
              class="logo-preview"
          />

          <div class="meep-input">
            <!-- Email Banner -->
            <md-field>
              <label>
                Bandeau email
              </label>

              <md-file
                  accept=".jpg"
                  @md-change="onFileAdded($event, 'banner')"
              />
            </md-field>
          </div>

          <img
              v-show="bannerPreview !== null"
              :src="bannerPreview"
              alt=""
              class="banner-preview"
          />

          <div class="meep-input">
            <!-- Footer Banner -->
            <md-field>
              <label>
                Bandeau footer
              </label>

              <md-file
                  accept=".jpg"
                  @md-change="onFileAdded($event, 'footerBanner')"
              />
            </md-field>
          </div>

          <img
              v-show="footerBannerPreview"
              :src="footerBannerPreview"
              alt=""
              class="banner-preview"
          />

          <md-checkbox v-model="form.has_email_synchro">
            {{ $t("organization.has_email_synchro") }}
          </md-checkbox>

          <md-checkbox v-model="form.has_lock_folder_ability">
            {{ $t("organization.has_lock_folder_ability") }}
          </md-checkbox>

          <md-checkbox v-model="form.hasPushNotification">
            Activer le push
          </md-checkbox>

          <div class="meep-input" v-if="form.hasPushNotification">
            <md-field>
              <label>Firebase Service Account</label>

              <md-file
                  accept=".json"
                  @md-change="onFileAdded($event, 'firebase')"
              />
            </md-field>
          </div>

          <!-- Cloud? -->
          <md-checkbox v-model="form.has_cloud">
            Activer le cloud
          </md-checkbox>

          <template v-if="form.has_cloud">
            <md-checkbox v-model="form.hasCloudSync">
              Activer la synchro
            </md-checkbox>

            <!-- Identifiant de compte -->
            <div class="meep-input">
              <md-field>
                <label>
                  Identifiant de compte Cloud *
                </label>

                <md-input
                    v-model="form.owncloud_id"
                    v-validate="'required'"
                    name="owncloud_id"
                    type="text"
                />

                <span v-show="errors.has('owncloud_id')" class="md-error">
                  {{ errors.first("owncloud_id") }}
                </span>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field
                  :class="{ 'md-invalid': errors.has('owncloud_password') }"
              >
                <label>
                  Mot de passe Cloud *
                </label>

                <md-input
                    v-model="form.owncloud_password"
                    v-validate="'required'"
                    name="owncloud_password"
                    type="text"
                />

                <span v-show="errors.has('owncloud_password')" class="md-error">
                  {{ errors.first("owncloud_password") }}
                </span>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>URL du Cloud</label>

                <md-input
                    v-model="form.owncloud_endpoint"
                    v-validate="'required'"
                    name="owncloud_endpoint"
                    type="text"
                />

                <span v-show="errors.has('owncloud_endpoint')" class="md-error">
                  {{ errors.first("owncloud_endpoint") }}
                </span>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>Racine du Cloud</label>

                <md-input
                    v-model="form.owncloud_root"
                    name="owncloud_root"
                    type="text"
                />
              </md-field>
            </div>
          </template>

          <h4>Informations complémentaires :</h4>

          <!-- Numéro de SIREN -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('siren') }">
              <label>
                Numéro de SIREN
              </label>

              <md-input
                  v-model="form.siren"
                  v-validate="'numeric|min:9|max:9'"
                  name="siren"
                  type="text"
              />

              <span v-show="errors.has('siren')" class="md-error">
                {{ errors.first("siren") }}
              </span>
            </md-field>
          </div>

          <!-- Email -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('email') }">
              <label>Adresse e-mail</label>

              <md-input
                  v-model="form.email"
                  v-validate="'email'"
                  name="email"
                  type="email"
              />

              <span v-show="errors.has('email')" class="md-error">
                {{ errors.first("email") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <!-- Numéro de téléphone -->
            <md-field :class="{ 'md-invalid': errors.has('tel') }">
              <label>Numéro de téléphone</label>

              <md-input
                  v-model="form.tel"
                  v-validate="'telephone'"
                  name="tel"
                  type="tel"
              />

              <span v-show="errors.has('tel')" class="md-error">
                {{ errors.first("tel") }}
              </span>
            </md-field>
          </div>

          <!-- Site Web -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('url') }">
              <label>Site web</label>

              <md-input
                  v-model="form.url"
                  v-validate="'url'"
                  name="url"
                  type="url"
              />

              <span v-show="errors.has('url')" class="md-error">
                {{ errors.first("url") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label>URL d'espace client *</label>

              <md-input
                  v-model="form.homepageUrl"
                  v-validate="'required'"
                  name="homepageUrl"
                  type="text"
              />

              <span v-show="errors.has('homepageUrl')" class="md-error">
                {{ errors.first("homepageUrl") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label>URL de l'application Android</label>

              <md-input
                  v-model="form.androidUrl"
                  name="androidUrl"
                  type="text"
              />

              <span v-show="errors.has('androidUrl')" class="md-error">
                {{ errors.first("androidUrl") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label>URL de l'application iOS</label>

              <md-input v-model="form.iOsUrl" name="iOsUrl" type="text" />

              <span v-show="errors.has('iOsUrl')" class="md-error">
                {{ errors.first("iOsUrl") }}
              </span>
            </md-field>
          </div>

          <!-- Numéro et voie -->
          <div class="meep-input">
            <md-field>
              <label>Adresse</label>

              <md-input v-model="form.adresse" name="adresse" type="text" />
            </md-field>
          </div>

          <!-- Ville -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('ville') }">
              <label>Ville</label>

              <md-input
                  v-model="form.ville"
                  v-validate="'alpha_spaces'"
                  name="ville"
                  type="ville"
              />

              <span v-show="errors.has('ville')" class="md-error">
                {{ errors.first("ville") }}
              </span>
            </md-field>
          </div>

          <!-- Code postal -->
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
              <label>
                Code postal
              </label>

              <md-input
                  v-model="form.codepostal"
                  v-validate="'numeric|min:5|max:5'"
                  name="codepostal"
                  type="text"
              />

              <span v-show="errors.has('codepostal')" class="md-error">
                {{ errors.first("codepostal") }}
              </span>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label>{{ $t("organization.contact_email") }}</label>

              <md-input
                  v-model="form.contact_email"
                  name="contact_email"
                  type="text"
              />
            </md-field>
          </div>

          <div class="md-layout md-alignment-center-right">
            <md-button
                class="md-raised md-primary"
                @click.native="createOrganization"
            >
              Créer un cabinet
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import organizationsModel from "../../../model/organizations";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      logoPreview: null,
      bannerPreview: null,
      logoMinifiedPreview: null,
      form: {
        name: "",
        has_cloud: false,
        cloud_vendor: 0,
        owncloud_id: "",
        owncloud_password: "",
        owncloud_endpoint: "",
        owncloud_root: "",
        homepageUrl: "",
        androidUrl: "",
        iOsUrl: "",
        hasCloudSync: false,
        color_scheme: 0,
        siren: "",
        adresse: "",
        codepostal: "",
        ville: "",
        tel: "",
        email: "",
        url: "",
        logo: null,
        logoMinified: null,
        emailBanner: null,
        footerBanner: null,
        has_email_synchro: false,
        hasPushNotification: false,
        contact_email: "",
      },
    };
  },
  methods: {
    name: "CreateOrganization",
    goBack() {
      window.history.back();
    },
    async createOrganization() {
      const result = await this.$validator.validateAll();

      if (result) {
        try {
          await organizationsModel.create(this.form);

          this.$toasted.global.AppSucces({
            message: "Le cabinet a bien été créé",
          });
          this.$router.push("/admin/organizations/");
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$toasted.global.AppInfo({
          message: "Vous devez remplir les champs manquants",
        });
      }
    },
    onFileAdded(files, type) {
      if (!files) return;

      const logo = Array.from(files)[0];

      const reader = new FileReader();
      let ctx = this;

      reader.onload = function(e) {
        if (type === "logo") {
          ctx.logoPreview = e.target.result;
          ctx.$forceUpdate();
          ctx.form.logo = ctx.logoPreview;
        } else if (type === "logoMinified") {
          ctx.logoMinifiedPreview = e.target.result;
          ctx.$forceUpdate();
          ctx.form.logoMinified = ctx.logoMinifiedPreview;
        } else if (type === "footerBanner") {
          ctx.footerBannerPreview = e.target.result;
          ctx.$forceUpdate();
          ctx.form.footerBanner = ctx.footerBannerPreview;
        } else if (type === "firebase") {
          ctx.form.firebaseAccountService = logo;
        } else {
          ctx.bannerPreview = e.target.result;
          ctx.$forceUpdate();
          ctx.form.emailBanner = ctx.bannerPreview;
        }
      };

      if (type !== "firebase") {
        reader.readAsDataURL(logo);
      } else {
        reader.readAsText(logo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.organizations-create {
  .md-card {
    margin: 32px auto;
  }
}
.logo-preview {
  width: 220px;
  height: 128px;
  margin: 2rem 0;
}
.banner-preview {
  margin: 2rem 0;
}
</style>
